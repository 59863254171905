import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeComponent from "./Home";
import RSVPComponent from "./RSVP";
import "./App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/rsvp" element={<RSVPComponent />} />
        </Routes>
      </BrowserRouter>
      <footer></footer>
    </div>
  );
}

export default App;
